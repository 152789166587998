import React, { useEffect } from "react";
import { Link } from "gatsby";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const PublicationCard = ({ publication, publicationStyles }) => {
  const slugify = require("slugify");
  const animationControl = useAnimation();
  const boxVariants = {
    visible: { opacity: 1, x: 0, transition: { duration: 0.8 } },
    hidden: { opacity: 0, x: 300 },
  };
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      animationControl.start("visible");
    }
  }, [inView, animationControl]);

  return (
    <motion.li
      ref={ref}
      initial="hidden"
      variants={boxVariants}
      animate={animationControl}
      key={publication.id}
      className={`my-2 px-3 bg-white mb-3 shadow-md ${
        publication.intro && "py-4"
      }`}
    >
      <Link
        className={`flex items-center text-lg leading-6 font-normal ${!publication.intro ? "py-4" : ""}`}
        to={`/publications/${slugify(publication.title, {
          lower: true,
          strict: true,
        })}`}
      >
        <span
          className={`p-0 m-0 -ml-2 text-left material-icons-outlined ${
            publication.type === "Livre"
              ? publicationStyles.livre.text
              : publicationStyles.article.text
          }`}
        >
          arrow_right
        </span>
        {publication.title}
      </Link>
      {publication.intro && (
        <p className="mt-1">
          <i className="text-gray-600 text-sm">{publication.intro}</i>
        </p>
      )}
    </motion.li>
  );
};

export default PublicationCard;

import React, { useState, useEffect } from "react";
import { Link, navigate } from "gatsby";
import PublicationCard from "./PublicationCard";
import "./Publications.css";

const publicationStyles = {
  livre: {
    text: "text-red-500",
    border: "border-red-500",
    bg: "bg-red-500",
  },
  article: {
    text: "text-gray-900",
    border: "border-gray-900",
    bg: "bg-gray-900",
  },
  all: {
    text: "text-gray-700",
    border: "border-white",
    bg: "bg-gray-300",
  },
};

const Publications = ({
  publications,
  title,
  description,
  subtitle1,
  subtitle2,
  page,
  subpage,
  keywords,
}) => {
  const [type, setType] = useState("");
  const [tag, setTag] = useState("");
  const [filteredPublications, setFilteredPublications] = useState([]);

  const resetSearch = () => {
    localStorage.setItem("tag", "");
    setTag("");
    localStorage.setItem("publication_type", "");
    setType("");
    return true;
  };

  useEffect(() => {
    if (localStorage.getItem("publication_type")) {
      setType(localStorage.getItem("publication_type"));
    }
    if (localStorage.getItem("tag")) {
      setTag(localStorage.getItem("tag"));
    }
    if (publications) {
      setFilteredPublications(
        publications
          .filter((publication) => {
            return (
              page !== true ||
              type === "" ||
              (type.length > 0 && publication.type === type)
            );
          })
          .filter((publication) => {
            if (page !== true || tag === "") {
              return true;
            } else if (tag.length > 0 && publication.keywords) {
              var keywordsMatched = publication.keywords.map((keyword) => {
                if (keyword.keywords_id !== null) {
                  return keyword.keywords_id.name === tag;
                } else {
                  return false;
                }
              });
              for (var i = 0; i < keywordsMatched.length; i++) {
                if (keywordsMatched[i]) {
                  return true;
                }
              }
            }
            return false;
          })
      );
    }
  }, [publications, page, tag, type]);

  const changeType = (newType) => {
    if (newType === type) {
      localStorage.setItem("publication_type", "");
      setType("");
    } else {
      localStorage.setItem("publication_type", newType);
      setType(newType);
    }
    navigate("/publications#liste");
  };

  const changeTag = (newTag) => {
    if (newTag === tag) {
      localStorage.setItem("tag", "");
      setTag("");
    } else {
      localStorage.setItem("tag", newTag);
      setTag(newTag);
    }
    navigate("/publications#liste");
  };

  const sizeFromLength = (nb, keywordsPublications) => {
    const averagekeywordsPublications =
      keywordsPublications.reduce((accu, current) => {
        return parseInt(accu) + parseInt(current.publications.length);
      }, 0) / parseInt(keywordsPublications.length);

    const wordLength1 = parseInt(averagekeywordsPublications) + 1;
    const wordLength2 = parseInt(averagekeywordsPublications);
    const wordLength3 = parseInt(averagekeywordsPublications) - 1;

    var classTextSize = "text-sm";
    if (nb >= wordLength1) {
      classTextSize = "text-2xl md:text-2xl5 leading-7";
    } else if (nb >= wordLength2) {
      classTextSize = "text-lg md:text-xl leading-5";
    } else if (nb >= wordLength3) {
      classTextSize = "text-base md:text-lg leading-4";
    } else {
      classTextSize = "text-sm md:text-base leading-4";
    }
    return classTextSize;
  };

  return (
    <div className="publications overflow-hidden max-w-6xl mx-auto px-3 sm:px-6 pt-8 pb-20 flex items-center">
      <div className="mx-auto w-full">
        {page === true ? (
          <h1 className="text-center mt-6 mb-8">{title}</h1>
        ) : (
          <h3 className="text-center w-auto pt-8 mb-8">{title}</h3>
        )}
        <hr
          className={`border-none h-2 ${
            type === "Livre" && page === true
              ? publicationStyles.livre.bg
              : type === "Article" && page === true
              ? publicationStyles.article.bg
              : page === true
              ? publicationStyles.all.bg
              : page !== true
              ? publicationStyles.livre.bg
              : ""
          }`}
        />
        {description && (
          <div className="text-center max-w-3xl mx-auto mt-6 mb-8">
            <p className="text-lg text-gray-600">{description}</p>
          </div>
        )}
        <div className={`grid grid-cols-2 gap-8`}>
          {subpage !== true && (
            <div
              className={`col-span-2 ${
                page === true ? "lg:col-span-2" : "lg:col-span-1"
              }`}
            >
              <h4 className="text-center lg:text-center pl-3 mb-2">
                {subtitle1}
              </h4>
              <div className="transform relative bg-white py-8 px-4 text-center max-w-3xl w-auto mx-auto rounded shadow-lg">
                <div className="flex flex-wrap items-center justify-center">
                  {keywords &&
                    keywords.map((keyword) => {
                      return (
                        <button
                          key={keyword.id}
                          className={`text-gray-700 p-1 cursor-pointer ${sizeFromLength(
                            keyword.publications.length,
                            keywords
                          )} ${
                            tag === keyword.name &&
                            `${
                              publicationStyles[
                                type ? type.toLowerCase() : "all"
                              ].text
                            } font-bold`
                          }`}
                          onClick={() => changeTag(keyword.name)}
                        >
                          {keyword.name}
                        </button>
                      );
                    })}
                </div>
              </div>
            </div>
          )}
          {page === true && (
            <div
              className={`${
                page === true
                  ? "col-span-2 lg:col-span-2"
                  : "col-span-2 lg:col-span-1"
              }`}
            >
              <h4 className="text-center mb-3">Filtrer par genre</h4>
              <div className="grid grid-cols-2 gap-4 md:gap-8">
                {/* <button
                  className={`md:mx-2 btn rounded-none md:rounded text-white ${
                    type === ""
                      ? `${publicationStyles.all.bg}`
                      : `opacity-70 bg-gray-700 hover:${publicationStyles.all.bg}`
                  }`}
                  onClick={() => changeType("")}
                >
                  Tout
                </button> */}
                <div className="place-self-end">
                  <button
                    className={`btn text-white transform ${
                      type === "Livre"
                        ? `${publicationStyles.livre.bg} scale-110`
                        : `opacity-70 scale-100 bg-gray-700 hover:${publicationStyles.livre.bg}`
                    }`}
                    onClick={() => changeType("Livre")}
                  >
                    Livres
                  </button>
                </div>
                <div className="place-self-start">
                  <button
                    className={`btn text-white transform ${
                      type === "Article"
                        ? `${publicationStyles.article.bg} scale-110`
                        : `opacity-70 scale-100 bg-gray-700 hover:${publicationStyles.article.bg}`
                    }`}
                    onClick={() => changeType("Article")}
                  >
                    Articles
                  </button>
                </div>
              </div>
            </div>
          )}
          <div
            id="liste"
            className={`${
              page === true || subpage === true
                ? "col-span-2"
                : "col-span-2 lg:col-span-1 lg:pl-4"
            }`}
          >
            {subtitle2 ? (
              <h4 className="text-center lg:text-left mb-2">{subtitle2}</h4>
            ) : (
              <div>
                <p className="flex">
                  <span className="text-sm md:text-base">
                    {filteredPublications.length}
                  </span>
                  <span className="ml-1 text-sm md:text-base">
                    publication{filteredPublications.length > 1 && "s"}
                    <span className="hidden sm:inline-block sm:pl-1">
                      trouvée{filteredPublications.length > 1 && "s"}
                    </span>
                  </span>
                  {tag && (
                    <span className="text-sm md:text-base ml-4 pl-3 border-l-2 border-gray-400">
                      <strong className="hidden sm:inline-block">
                        Mot-clé :{" "}
                      </strong>
                      <button
                        onClick={() => changeTag(tag)}
                        title="Retirer de la recherche"
                        className="px-2 text-gray-600"
                      >
                        {tag}{" "}
                        <span className="material-icons-outlined text-sm text-gray-500">
                          close
                        </span>
                      </button>
                    </span>
                  )}
                  {type && (
                    <span className="text-sm md:text-base ml-2 pl-3 border-l-2 border-gray-400">
                      <strong className="hidden sm:inline-block">
                        Genre :{" "}
                      </strong>
                      <button
                        onClick={() => changeType(type)}
                        title="Retirer de la recherche"
                        className="px-2 text-gray-600"
                      >
                        {type}s{" "}
                        <span className="material-icons-outlined text-sm text-gray-500">
                          close
                        </span>
                      </button>
                    </span>
                  )}
                </p>
                <hr
                  className={`w-full border-none h-1 my-2 ${
                    type === "Livre" ? publicationStyles.livre.bg : ""
                  } 
                  ${type === "Article" ? publicationStyles.article.bg : ""}
                  ${type === "" ? publicationStyles.all.bg : ""}
                  `}
                />
              </div>
            )}
            <div className={`${page === true && "min-h-50vh"}`}>
              {filteredPublications.length > 0 ? (
                <ul>
                  {filteredPublications.map((publication) => {
                    return (
                      <PublicationCard
                        key={publication.id}
                        publication={publication}
                        publicationStyles={publicationStyles}
                      />
                    );
                  })}
                </ul>
              ) : (
                <div>
                  <p className="h1 my-4">Aucun résultat</p>
                  <button
                    className="btn-sm"
                    type="button"
                    onClick={() => resetSearch()}
                  >
                    Réinitialiser mes critères de recherche
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        {page !== true && (
          <div className="text-center mt-12">
            <Link
              className="btn text-white font-bold uppercase bg-red-500 hover:bg-red-400"
              to="/publications"
            >
              <span className="material-icons-outlined text-white mr-3">
                list
              </span>
              Liste complète
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Publications;

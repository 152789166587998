import React from "react";
import { graphql, Link } from "gatsby";
import config from "../../data/SiteConfig";
import Layout from "../layout";
import Seo from "../components/Seo/Seo";
import Publications from "../components/Publications/Publications";
import Contact from "../components/Contact/Contact";
import GImage from "../components/Picture/GImage";

const PublicationsPage = ({ data: gqlData }) => {
  const { directus } = gqlData;
  return (
    <Layout>
      <div className="h-20 md:h-28"></div>
      {directus.settings &&
        directus.settings.publications_illustration !== null && (
          <GImage
            className="h-44 md:h-72 bg-publications bg-cover"
            src={directus.settings.publications_illustration.imageFile}
            alt="Image de la page des publications du secteur langues du Groupe Français d'éducation Nouvelle"
          />
        )}
      <div className="publications-container">
        <Seo
          title={`Publications`}
          description={config.siteDescription4}
          article={false}
        />

        <div className="fixed z-10 bottom-10 right-10">
          <Link
            className="btn bg-white text-gray-700 border-2 font-bold border-red-200 text-sm"
            title="Commander un livre"
            to="/bon-de-commande"
          >
            Commander
          </Link>
        </div>
        {directus.publications && directus.keywords && (
          <Publications
            title={directus.settings.publications_title}
            subtitle1="Rechercher par mot-clé"
            description={directus.settings.publications_description}
            publications={directus.publications}
            keywords={directus.keywords}
            page={true}
          />
        )}
        {directus.contact && (
          <Contact contact={directus.contact} background={true} />
        )}
      </div>
    </Layout>
  );
};

export default PublicationsPage;

export const pageQuery = graphql`
  query {
    directus: allDirectus {
      keywords(filter: { publications: { id: { _nnull: true } } }) {
        id
        name
        publications {
          id
        }
      }
      publications(sort: "-sort") {
        id
        title
        intro
        type
        keywords {
          keywords_id {
            name
            publications {
              id
            }
          }
        }
      }
      settings {
        id
        publications_title
        publications_description
        publications_illustration {
          id
          imageFile {
            id
            childImageSharp {
              id
              gatsbyImageData(
                quality: 80
                layout: FULL_WIDTH
                placeholder: TRACED_SVG
                tracedSVGOptions: { color: "#d8b0b1" }
              )
            }
          }
        }
      }
      contact {
        title
        subtitle
        intro
        informations
        illustration {
          id
          imageFile {
            id
            childImageSharp {
              id
              gatsbyImageData(
                quality: 80
                layout: FULL_WIDTH
                placeholder: TRACED_SVG
                tracedSVGOptions: { color: "#d8b0b1" }
              )
            }
          }
        }
      }
    }
  }
`;
